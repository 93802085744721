import { FunctionComponent, useContext, useEffect, useState } from 'react';

import UserAgentContext from 'components/context/UserAgent';
import LayoutContainer from 'components/layout/LayoutContainer';
import Slick from 'components/layout/Slick';
import SmallJobBox from 'components/modules/public/layout/jobs/SmallJobBox';
import SmallJobBoxMobile from 'components/modules/public/layout/jobs/SmallJobBoxMobile';
import Description from 'components/modules/public/layout/sections/Description';

import StyledComponent from './styles';
import { Props } from './types';

const PublicPageHomeSectionRecommendedOffers: FunctionComponent<Props> = ({ translations: { descriptionProps }, layoutContainerProps, jobs, iconsAlts }) => {
    const [isMobileUserAgent, setIsMobileUserAgent]: [boolean, Function] = useState(null);
    const context = useContext(UserAgentContext);

    useEffect(() => {
        setIsMobileUserAgent(context);
    }, [context]);

    if(!Array.isArray(jobs) || jobs.length === 0) {
        return null;
    }

    const settings = {
        arrows: true,
        dots: true,
        slidesToShow: jobs.length < 4 && jobs.length || 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <StyledComponent className="public-page-home-section-recommended-offers">
            <LayoutContainer {...layoutContainerProps}>
                <div className="arrow-scroll-down">
                    <a href="#top-offers">
                        <img
                            src="/images/icons/arrow-scroll-down1-black.svg"
                            className="arrow up"
                            loading="lazy"
                        />
                        <img
                            src="/images/icons/arrow-scroll-down2-black.svg"
                            className="arrow down"
                            loading="lazy"
                        />
                    </a>
                </div>
                <Description {...descriptionProps}>
                    <div
                        id="top-offers"
                        className='anchor'
                    ></div>
                    { isMobileUserAgent ? (
                        <div className="offer-boxes-mobile">
                            <Slick sliderProps={settings}>
                                {jobs.map(element => (
                                    <SmallJobBoxMobile
                                        key={element?.id}
                                        job={element}
                                        iconsAlts={iconsAlts}
                                    />
                                ))}
                            </Slick>
                        </div>
                    ) : (
                        <div className="offer-boxes">
                            <Slick sliderProps={settings}>
                                {jobs.map(element => (
                                    <SmallJobBox
                                        key={element?.id}
                                        job={element}
                                        iconsAlts={iconsAlts}
                                    />
                                ))}
                            </Slick>
                        </div>
                    )}
                </Description>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicPageHomeSectionRecommendedOffers;