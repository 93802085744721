import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
	@import url(${variables.fontHeadlineUrl});
	position: relative;
	height: 80vh;
	max-height: 50em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;

	.copy {
		transform: translate(0, -25%);
		color: #000000;
		max-width: 57em;
		position: relative;
		z-index: 9;

		.headline-wrapper {
			display: inline-block;

			.headline,
			.subheadline {
				font-family: ${variables.fontHeadline};
				font-size: 2.45em;
				font-weight: 600;
				padding: 0 0.9em 0.175em;
				background-position: 0 60%;
				background-repeat: no-repeat;
				background-size: 100%;
				line-height: normal;
			}
		}
	}

	@media all and (max-width: ${variables.tabletL}) {
		.copy {
			.headline-wrapper {
				margin-bottom: 0.5em;

				.headline,
				.subheadline {
					font-size: 2.1em;
				}
			}
		}
	}

	@media all and (max-width: ${variables.tabletS}) {
		.copy {
			.headline-wrapper {
				.headline,
				.subheadline {
					font-size: 1.8em;
				}
			}
		}
	}

	@media all and (max-width: ${variables.mobileL}) {
		.copy {
			.headline-wrapper {
				.headline,
				.subheadline {
					font-size: 1.45em;
				}
			}
		}
	}
`;
