import ApiHomePage from 'types/api/HomePage';

import Article from 'models/Article';
import City from 'models/City';
import CmsPage from 'models/CmsPage';
import Job from 'models/Job';
import JobTime from 'models/JobTime';

export default class HomePage {
    cities: City[];
    jobs: Job[];
    jobs_top: Job[];
    articles: Article[];
    jobTimes: JobTime[];
    cmsPage?: CmsPage;

    constructor(data: ApiHomePage) {
        this.cities = data?.cities && data.cities.map(city=> new City(city));
        this.jobs = data?.jobs && data?.jobs.map(job=>new Job(job));
        this.jobs_top = data?.jobs_top && data?.jobs_top.map(job=>new Job(job));
        this.articles = data?.articles && data?.articles.map(article=>new Article(article));
        this.jobTimes = data?.jobTimes && data?.jobTimes.map(jobTime=>new JobTime(jobTime));
        this.cmsPage = data?.cmsPage && new CmsPage(data?.cmsPage);
    }
}