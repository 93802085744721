import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: 5em 0;
    background-color: #F3F3F3;

    .article-boxes {
        margin: 3em 0;

        .layout-slick {
            .slick-slider {
                .slick-list {
                    .slick-track {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;

                        .slick-slide {
                            max-width: calc(100% / 3);

                            .public-layout-articles-medium-article-box {
                                min-height: 38em;
                            }
                        }
                    }
                }
            }
        }
    }

    .layout-button {
        padding-top: 2.5em;
    }

    @media all and (max-width: ${variables.tabletL}) {
        .article-boxes {
            .layout-slick {
                .slick-slider {
                    .slick-list {
                        .slick-track {
                            .slick-slide {
                                max-width: calc(100% / 2);
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        .article-boxes {
            .layout-slick {
                .slick-slider {
                    .slick-list {
                        .slick-track {
                            .slick-slide {
                                .public-layout-articles-medium-article-box {
                                    min-height: 43em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileM}) {
        .article-boxes {
            .public-layout-articles-medium-article-box {
                min-height: 0;
            }

            .layout-slick {
                .slick-slider {
                    .slick-list {
                        .slick-track {
                            .slick-slide {
                                max-width: 100%;

                                .public-layout-articles-medium-article-box {
                                    min-height: 32em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;