import { FunctionComponent } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { imageResizer } from 'utils/image';
import { withVariables } from 'utils/string';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutOffersSmallCityBox: FunctionComponent<Props> = ({ city }) => {
    return (
        <StyledComponent
            className="public-layout-offers-small-city-box"
            style={{
                backgroundImage: city?.image?.imageUrl &&
                    `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(${imageResizer(city?.image?.imageUrl, { width: 350 })})`,
            }}
        >
            <Link
                href={withVariables( Routes.PublicJobsCity, { jobCompanyCitySlug: city?.shortSlug })}
                prefetch={false}
                passHref={true}
            >
                <a className='link'>
                    <div
                        className={classnames(
                            'box-content',
                            {
                                layoutLeft: Boolean(!city?.image),
                                layoutCenter: Boolean(city?.image),
                            }
                        )}
                    >
                        <div
                            className="name"
                            dangerouslySetInnerHTML={{ __html: city?.name }}
                        />
                        {
                        // TODO: Hidden according to Martyna's request. Only while collecting offers
                        }
                        {/* <div
                        className="jobs-count"
                        dangerouslySetInnerHTML={{ __html: `${city?.jobsCount} ofert pracy` }}
                    /> */}
                    </div>
                </a>
            </Link>
        </StyledComponent>
    );
};

export default PublicLayoutOffersSmallCityBox;