import { ReactNode } from 'react';

import { Props as LayoutContainerProps } from 'components/layout/LayoutContainer';

import Component from './component';

export interface Props {
    background?: {
        desktop: [
            {
                id?: string;
                href: string;
                href1x: string;
                href2x: string;
                href3x: string;
            },
        ],
        mobile: [
            {
                id?: string;
                href: string;
                href1x: string;
                href2x: string;
                href3x: string;
            },
        ],
    };
    layoutContainerProps?: LayoutContainerProps;
    underline?: string;
    headline?: string;
    subheadline?: string;
    infoHeadline?: string;
    headlineMobile?: string;
    headlineTwoMobile?: string;
    subHeadlineMobile?: string;
    children?: ReactNode;
    isSafari?: boolean;
}

Component.defaultProps = {
    background: null,
    layoutContainerProps: null,
    underline: null,
    headline: null,
    subheadline: null,
    children: null,
};

export default Component;