import { FunctionComponent } from 'react';

import LayoutContainer from 'components/layout/LayoutContainer';
import Slick from 'components/layout/Slick';
import MediumArticleBox from 'components/modules/public/layout/articles/MediumArticleBox';
import Description from 'components/modules/public/layout/sections/Description';

import StyledComponent from './styles';
import { Props } from './types';

const PublicPageHomeSectionArticles: FunctionComponent<Props> = ({ layoutContainerProps, translations: { descriptionProps, iconsAlts }, articles }) => {
    if(!Array.isArray(articles) || articles.length === 0) {
        return null;
    }

    const settings = {
        arrows: true,
        dots: true,
        slidesToShow: articles.length < 3 && articles.length || 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <StyledComponent className="public-page-home-section-articles">
            <LayoutContainer {...layoutContainerProps}>
                <Description {...descriptionProps}>
                    <div className="article-boxes">
                        <Slick sliderProps={settings}>
                            {articles.map(element => (
                                <MediumArticleBox
                                    key={element?.id}
                                    article={element}
                                    iconsAlts={iconsAlts}
                                />
                            ))}
                        </Slick>
                    </div>
                </Description>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicPageHomeSectionArticles;