import { FunctionComponent } from 'react';

import City from 'models/City';

import LayoutContainer from 'components/layout/LayoutContainer';
import SmallCityBox from 'components/modules/public/layout/cities/SmallCityBox';
import Description from 'components/modules/public/layout/sections/Description';

import StyledComponent from './styles';
import { Props } from './types';

const PublicPageHomeSectionJobLocations: FunctionComponent<Props> = ({ layoutContainerProps, translations: { descriptionProps }, cities }) => {
    return (
        <StyledComponent
            className="public-page-home-section-job-locations"
        >
            <LayoutContainer
                {...layoutContainerProps}
            >
                <Description
                    {...descriptionProps}
                >
                    <div className="list">
                        {Array.isArray(cities) && cities.length > 0 && cities
                            .sort((a: City, b: City) => b.priority - a.priority)
                            .map(element => (
                                <SmallCityBox
                                    key={element?.id}
                                    city={element}
                                />
                            ))}
                    </div>
                </Description>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicPageHomeSectionJobLocations;