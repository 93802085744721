import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: 5em 0;

    .list {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        padding: 3em 0;

        > * {
            width: 24%;

            .layoutLeft {
                padding-top: 2em;
            }
        }
    }

    @media all and (max-width: ${variables.tabletS}) {}

    @media all and (max-width: ${variables.mobileM}) {
        padding: 5em 1.1em;

        .list {
            flex-flow: column nowrap;

            > * {
                width: 100%;
                margin-bottom: 1em;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .layoutLeft {
                    padding-top: 1em;
                }
            }
        }
    }
`;