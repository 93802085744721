import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
	position: relative;
	width: 100%;
	padding: 10em 0 5em 0;
	background-color: ${variables.colorBackgroundContrast};

	.header {
		.top {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			padding-bottom: 2em;

			.headline {
				font-size: 2em;
				font-weight: 700;
			}
		}

		.description {
			font-size: 1.2em;
			padding-bottom: 2em;
		}
	}

	.offer-boxes {
		padding: 3em 0;
	}

	.offer-boxes-mobile {
		padding: 3em 0;
	}

	@media all and (max-width: ${variables.mobileM}) {
		padding: 5em 0;

		.header {
			padding-left: 1em;
		}

		.offer-boxes-mobile {
			display: block;
		}

		.layout-button {
			font-size: 125%;

			.button {
				padding: 1.2em 2.5em;
			}
		}

        .header-button {
            .button {
                padding: 1em 0 ;
                font-size: 0.8em;

                .button-icon-image{
                   max-width: max-content;
                }
            }
        }
	}
`;
