import { FunctionComponent, useState } from 'react';
import dynamic from 'next/dynamic';

import { Types } from 'models/Subscriber';

import LayoutContainer from 'components/layout/LayoutContainer';
import Spinner from 'components/layout/Spinner/component';
import NewsletterForm from 'components/modules/public/layout/forms/NewsletterForm';
import Description from 'components/modules/public/layout/sections/Description';

import StyledComponent from './styles';
import { Props } from './types';

const DynamicAnimation = dynamic(
    () => import('components/modules/public/layout/animations/NewsletterAnimation'),
    {
        loading: () => <Spinner />,
        ssr: false,
    }
);

const PublicPageHomeSectionNewsletter: FunctionComponent<Props> = ({ layoutContainerProps, translations: { descriptionProps, form } }) => {
    const [isSubscribed, setIsSubscribed]: [boolean, Function] = useState(false);

    return (
        <StyledComponent
            className="public-page-home-section-newsletter"
        >
            <LayoutContainer
                {...layoutContainerProps}
            >
                {!isSubscribed
                    ? (
                        <Description {...descriptionProps}>
                            {form && (
                                <NewsletterForm
                                    onSuccess={(response) => setIsSubscribed(Boolean(response))}
                                    type={Types.Worker}
                                    {...form}
                                />
                            )}
                        </Description>
                    )
                    : (
                        <Description
                            {...descriptionProps}
                            headline={descriptionProps?.subscribed?.headline}
                            description={descriptionProps?.subscribed?.description}
                        />
                    )
                }
                <DynamicAnimation />
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicPageHomeSectionNewsletter;