import { FunctionComponent, useContext, useEffect, useState } from 'react';

import UserAgentContext from 'components/context/UserAgent';
import Button, { ButtonIconPositions, ButtonIconTypes, ButtonStyles, ButtonVariants } from 'components/layout/Button';
import LayoutContainer from 'components/layout/LayoutContainer';
import Slick from 'components/layout/Slick';
import SmallJobBox from 'components/modules/public/layout/jobs/SmallJobBox';
import SmallJobBoxMobile from 'components/modules/public/layout/jobs/SmallJobBoxMobile';

import StyledComponent from './styles';
import { Props } from './types';

const PublicPageHomeSectionOffers: FunctionComponent<Props> = ({ translations: { descriptionProps }, layoutContainerProps, jobs, iconsAlts }) => {
    const [isMobileUserAgent, setIsMobileUserAgent]: [boolean, Function] = useState(null);
    const context = useContext(UserAgentContext);

    useEffect(() => {
        setIsMobileUserAgent(context);
    }, [context]);

    if(!Array.isArray(jobs) || jobs.length === 0) {
        return null;
    }

    const [expand, setExpand]: [boolean, Function] = useState(false);

    const settings = {
        arrows: true,
        dots: false,
        slidesToShow: jobs.length < 4 && jobs.length || 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    dots: true,
                    centerMode: true,
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <StyledComponent className="public-page-home-section-offers">
            <LayoutContainer {...layoutContainerProps}>
                <div className="header">
                    <div className="top">
                        <div
                            className="headline"
                            dangerouslySetInnerHTML={{ __html: descriptionProps?.headline }}
                        />
                        <Button
                            className="header-button"
                            style={ButtonStyles.Secondary}
                            variant={ButtonVariants.Outline}
                            onClick={() => setExpand(!expand)}
                            icon={{
                                value: expand ? '/images/jobs/arrow-top.svg' : '/images/jobs/arrow-bottom.svg',
                                hover: expand ? '/images/jobs/arrow-top.svg' : '/images/jobs/arrow-bottom.svg',
                                position: ButtonIconPositions.After,
                                type: ButtonIconTypes.Image,
                                width: 24,
                                height:24,
                            }}
                        >
                            Więcej
                        </Button>
                    </div>
                    {expand && (
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{ __html: descriptionProps?.description }}
                        />
                    )}
                </div>
                { isMobileUserAgent ? (
                    <div className="offer-boxes-mobile">
                        <Slick sliderProps={settings}>
                            {jobs.map(element => (
                                <SmallJobBoxMobile
                                    key={element?.id}
                                    job={element}
                                    iconsAlts={iconsAlts}
                                />
                            ))}
                        </Slick>
                    </div>
                ) : (
                    <div className="offer-boxes">
                        <Slick sliderProps={settings}>
                            {jobs.map(element => (
                                <SmallJobBox
                                    key={element?.id}
                                    job={element}
                                    iconsAlts={iconsAlts}
                                />
                            ))}
                        </Slick>
                    </div>
                )}
                <Button {...descriptionProps?.buttonProps} />
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicPageHomeSectionOffers;