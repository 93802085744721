import { ApiResources } from 'types/apiResources';
import { Endpoints, ListParamsDefault } from 'types/endpoints';

import HomePage from 'models/HomePage';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface ListParams extends ListParamsDefault {
    salaryFrom?: number,
    jobCategorySlug?: string[],
    jobAgreementSlug?: string[],
    jobModeSlug?: string[],
    jobExperienceSlug?: string[],
    jobLanguageSlug?: string[],
}
export const listHomePage = async (params?: ListParams): Promise<HomePage> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicHomePage, params),
        requestParams: params,
    });
    const data = await response.payload?.data;
    const newData = {
        cities: data && data[ApiResources.Cities]?.elements,
        articles: data && data[ApiResources.Articles]?.elements,
        jobTimes: data && data[ApiResources.JobTimes]?.elements,
        jobs:  data && data[ApiResources.Jobs]?.elements,
        jobs_top: data && data[ApiResources.JobsTop]?.elements,

    };
    if(!data) {
        throw new Error('Not Found');
    }
    return new HomePage(newData);
};

