import { FunctionComponent } from 'react';

import { imageResizer } from 'utils/image';

import LayoutContainer from 'components/layout/LayoutContainer';
import Description from 'components/modules/public/layout/sections/Description';

import StyledComponent from './styles';
import { Props } from './types';

const PublicPageHomeSectionLeads: FunctionComponent<Props> = ({ layoutContainerProps, translations:{ descriptionProps, image } }) => {
    return (
        <StyledComponent
            className="public-page-home-section-leads"
        >

            <img
                className='image'
                src={imageResizer(image, { width: 953 })}
                alt="Ludzie na spotkaniu biznesowym"
                loading='lazy'
            />
            <LayoutContainer
                {...layoutContainerProps}
            >
                <Description {...descriptionProps} />
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicPageHomeSectionLeads;