import { FunctionComponent, useEffect, useRef } from 'react';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutSectionHeroSlider: FunctionComponent<Props> = ({
    background,
    background1x,
    background2x,
    background3x,
    id,
}) => {
    const animationImage = useRef(null);

    useEffect(() => {
        const animation = animationImage?.current;
        const AnimationInterval = setInterval(() => {
            animation?.classList.contains('animation')
                ? animation?.classList.remove('animation')
                : animation?.classList.add('animation');
        }, 4500);

        return () => clearInterval(AnimationInterval);
    }, []);

    return (
        <StyledComponent className="public-layout-section-hero-slider">
            {id > 1 ? (
                <picture>
                    <source
                        media="(min-width: 2800px)"
                        srcSet={background3x}
                    />
                    <source
                        media="(min-width: 1920px)"
                        srcSet={background2x}
                    />
                    <source
                        media="(min-width: 640px)"
                        srcSet={background1x}
                    />
                    <img
                        className="image"
                        ref={animationImage}
                        src={background}
                    />
                </picture>
            ) : (
                <picture>
                    <source
                        media="(min-width: 2800px)"
                        srcSet={background3x}
                    />
                    <source
                        media="(min-width: 1920px)"
                        srcSet={background2x}
                    />
                    <source
                        media="(min-width: 640px)"
                        srcSet={background1x}
                    />
                    <img
                        className="image"
                        src={background}
                    />
                </picture>
            )}
        </StyledComponent>
    );
};

export default PublicLayoutSectionHeroSlider;
