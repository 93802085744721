import { FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { Routes } from 'types/routes';

import { isBrowser, isIos, isSafari } from 'utils/browser';
import { getOptionValue } from 'utils/option';
import { withVariables } from 'utils/string';

import LayoutContainer from 'components/layout/LayoutContainer';
import SearchForm, { MainBlockValues } from 'components/modules/public/layout/forms/SearchForm';
import SectionHero from 'components/modules/public/layout/sections/Hero';
import SectionHeroMobile from 'components/modules/public/layout/sections/HeroMobile';
import { ISearchParam, SearchParamCity, SearchParamPage, SearchParamTime, SearchParamValue, SearchParamValues, useSearchParams } from 'hooks/useSearchParams';

import StyledComponent from './styles';
import { Props } from './types';


const PublicPageHomeSectionHero: FunctionComponent<Props> = ({ translations: { background, underline, headline }, form }) => {
    const router = useRouter();

    // const [values, setValues]: [MainBlockValues, Function] = useState(null);
    const [safari, setSafari]: [boolean, Function] = useState(false);

    useEffect(() => {
        if (isBrowser() && isSafari() || isIos()) setSafari(true);
        else setSafari(false);
    }, []);

    const { getSearchParams, getSearchParamsQueryValues, getSearchParamValue, setSearchParamValue } = useSearchParams({

        city: new SearchParamCity('jobCompanyCitySlug'),
        time: new SearchParamTime('jobTimeSlug'),
        radius: new SearchParamValue('rangeRadius'),
        name: new SearchParamValue('name'),
    });

    const searchParams = getSearchParams();

    /**
     * Update router query string
     *
     * @param attributes: any = {}
     */
    const updateQueryString = (searchParams: any, hash: string = '') => {
        const urlSearchParams = new URLSearchParams();

        Object.keys(searchParams)
            .map(key => {
                const value = searchParams[key].getQueryValue();
                if (value === '' || value === null || value === undefined || (Array.isArray(value) && value.length == 0)) return;
                urlSearchParams.set(searchParams[key].getQueryKey(), value);
            });

        router.push({
            pathname: Routes.PublicJobs,
            query: urlSearchParams.toString(),
            hash,
        }, undefined, { shallow: true });
    };

    const handleChangeSearchParam = (key: string, value: any, clear?: boolean) => {
        setSearchParamValue(key, value, clear);
    };

    const handleChangeSearchParamsAndSearch = (searchParams: any) => {
        Object.keys(searchParams)
            .map(key => {
                setSearchParamValue(key, searchParams[key].getValue());
            });

        updateQueryString({ ...getSearchParams() });
    };


    /*  const onChange = (key: string, value: any) => {
        if (key === 'companyCitySearch') {
            setValues({
                ...values,
                [key]: value,
            });
        } else {
            setValues({
                ...values,
                [key]: getOptionValue(value),
            });
        }
    };

    const onSearch = () => {
        if (values?.companyCitySearch) {
            const correctCompanyCitySearch = {
                jobCompanyCityName: values?.companyCitySearch?.label,
                jobCompanyCitySlug: values?.companyCitySearch?.value?.slug,
            };

            router.push(withVariables(Routes.PublicJobs, {}, {
                ...values,
                companyCitySearch: undefined,
                ...correctCompanyCitySearch,
            }));
        }
        else router.push(withVariables(Routes.PublicJobs, {}, { ...values }));
    }; */

    const tooltipOffsetDesktop =
    {
        top: 10,
        right: -50,
    };

    const tooltipOffsetMobile =
    {
        top: 10,
        right: -80,
    };

    return (
        <StyledComponent
            className="public-page-home-section-hero"
        >
            <SectionHero
                background={background}
                underline={underline}
                headline={headline}
                isSafari={safari}
            >
                <LayoutContainer className="form-container">
                    <SearchForm

                        searchParams={searchParams}
                        onChangeSearchParam={handleChangeSearchParam}
                        onChangeSearchParams={handleChangeSearchParamsAndSearch}

                        tooltipOffset={tooltipOffsetDesktop}
                        {...form}
                    />
                </LayoutContainer>

            </SectionHero>

            <SectionHeroMobile
                background={background}
                underline={underline}
                headline={headline}
                isSafari={safari}
            >
                <LayoutContainer className="form-container">
                    <SearchForm

                        searchParams={searchParams}
                        onChangeSearchParam={handleChangeSearchParam}
                        onChangeSearchParams={handleChangeSearchParamsAndSearch}

                        tooltipOffset={tooltipOffsetMobile}
                        {...form}
                    />
                </LayoutContainer>
            </SectionHeroMobile>
        </StyledComponent>
    );
};

export default PublicPageHomeSectionHero;