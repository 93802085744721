import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
	@import url(${variables.fontHeadlineUrl});
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 11em;
    width: 100%;

    .copy-container {
        position: relative;
        width: 100%;
        height: max-content;
        background-color: #F3F3F3;
        color: #000000;
        margin-top: 27.2vh;
        z-index: 99;

        .copy {
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 8em;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            z-index: 99;

            .headline-wrapper {
                display: inline-block;

                .headline, .subheadline {
                    font-family: ${variables.fontHeadline};
                    font-size: 1.9em;
                    font-weight: 600;
                    padding: .5em 1.5em 0;
                    background-position: center bottom;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    line-height: unset;
                }

                .subheadline {
                    background-size: 75%;
                    background-position-y: 1.75em;
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileM}) {
        .copy-container {
            .copy {
                .headline-wrapper {
                    .headline, .subheadline {
                        font-size: 1.9em;
                    }
                }
            }
        }
    }
`;