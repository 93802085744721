import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: 10em 0 5em 0;
    background-color: #F3F3F3;

    .arrow-scroll-down{
        position: relative;
        top: -3em;
        left: 50%;
        transform: translate(0, -50%);
        z-index: 999;

        .arrow {

        }

        .up {
            animation: move 1.5s infinite;
            animation-delay: 1s;
        }

        .down {
            opacity: 0;
            animation: show 1.5s infinite;
            animation-delay: 2s;
            animation: name duration timing-function delay iteration-count direction fill-mode;
        }
    }

    @keyframes move {
        from {
            transform: translateY(0%);
        }

        to {
            transform: translateY(100%);
        }
    }

    @keyframes show {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    .anchor {
        position: absolute;
        height: 2em;
        left: 0;
        top: -6em;
    }
    .offer-boxes {
        padding: 3em 0;

        .layout-slick {
            .slick-slider {
                .slick-list {
                    .slick-track {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;

                        .slick-slide {
                            max-width: calc(100% / 4);
                        }
                    }
                }
            }
        }
    }

    .layout-button {
        margin-top: 3em;
    }

    .offer-boxes-mobile {
        padding: 3em 0;
    }
    @media all and (max-width: ${variables.tabletL}) {
        .offer-boxes {
            .layout-slick {
                .slick-slider {
                    .slick-list {
                        .slick-track {
                            .slick-slide {
                                max-width: calc(100% / 2);
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${variables.tabletS}) {
        .arrow-scroll-down{
           display: none;
        }
    }
    @media all and (max-width: ${variables.mobileM}) {
        padding: 5em 0;

        .offer-boxes-mobile {
            display: block;
        }
    }
`;