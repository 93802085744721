import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: 5em 0;
    background-color: #10F5B0;
    z-index: 9;

    .layout-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .public-layout-description {
        width: 50%;

        .copy {
            .headline {
                br {
                    display: none;
                }
            }
        }
    }

    @media all and (max-width: ${variables.tabletS}) {}

    @media all and (max-width: ${variables.mobileM}) {
        padding: 5em 1em;

        .public-layout-description {
            width: 100%;

            .copy {
                .headline {
                    br {
                        display: block;
                    }
                }
            }
        }

        .animation {
            display: none;
        }
    }
`;