import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: relative;
	width: 100%;

	.form-container {
		position: absolute;
		bottom: -15%;
		left: 50%;
		transform: translate(-50%, -15%);
		z-index: 99;
	}

	.public-layout-section-hero-mobile {
		display: none;
	}

	@media all and (max-width: ${vars.tabletS}) {
		.form-container {
			bottom: -50%;
			transform: translate(-50%, -50%);
			margin-bottom: 2em;

			.public-layout-forms-search-form {
				.public-layout-forms-search-form-blocks-main {
					.title-row {
						padding-top: 1em;

						.form-title {
							font-size: 1em;
						}
					}

					.layout-forms-form {
						padding: 0 0.2em;

						form {
							font-size: 70%;
						}
					}

					.button-form-footer {
						padding-bottom: 0.5em;
						margin: 0;

						.layout-button {
							button {
								.button-content {
									font-size: 1em;
								}
							}
						}
					}
				}
			}
		}
	}

	@media all and (max-width: ${vars.mobileM}) {
		.public-layout-section-hero {
			display: none;
		}

		.public-layout-section-hero-mobile {
			display: flex;
		}

		.form-container {
			width: 100%;
			position: relative;
			bottom: 0;
			left: 0;
			transform: none;
            margin: 0;

			.public-layout-forms-search-form {
				.public-layout-forms-search-form-blocks-main {
					.layout-forms-form {
						form {
							font-size: 60%;
						}
					}
				}
			}
		}
	}
`;
