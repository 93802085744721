import ApiCmsPage from 'types/api/CmsPage';
import { ApiResources } from 'types/apiResources';
import { Endpoints, ListParamsDefault } from 'types/endpoints';
import ListCollection from 'types/redux/ListCollection';

import CmsPage from 'models/CmsPage';

import { LIST_MARKUP } from 'consts/redux';
import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface ListParams extends ListParamsDefault {}
export const list = async (params?: ListParams): Promise<ListCollection<CmsPage>> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicCmsPages, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource = data && data[ApiResources.CmsPages];

    if(!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...LIST_MARKUP,
        isLoaded: true,
        elements: resource
            .elements
            .map((element: ApiCmsPage) => new CmsPage(element)),
        meta: resource.meta,
    };
};

export interface SingleParams {
    slug: string;
}
export const single = async (params: SingleParams): Promise<CmsPage> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicCmsPage, params),
    });

    const data = response?.payload?.data;
    const resource: ApiCmsPage = data && data[ApiResources.CmsPage];

    if(!data || !resource) {
        throw new Error('Not Found');
    }

    return new CmsPage(resource);
};
