import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
	position: absolute;
	z-index: 0;
	top: 3em;
	left: 0vw;
	width: 100vw;
	height: 90vh;

	@keyframes opacityAnimation {
		0% {
			opacity: 0;
		}

		100% {
			display: block;
			opacity: 1;
		}
	}

	.image {
		object-fit: cover;
		width: 100vw;
		height: 90vh;
		opacity: 1;
		transition: opacity 1s ease-in-out;

		&.animation {
			opacity: 0;
		}
	}

	@media all and (max-width: ${variables.desktopS}) {
		top: 4em;
	}

	@media all and (max-width: ${variables.mobileM}) {
		top: 5em;

		.image {
			width: 100%;
			height: 45vh;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 70% 50%;
		}
	}
`;
