import { useContext } from 'react';
import { NextPage } from 'next';
import { title } from 'process';

import { selectCmsPageSection } from 'utils/locale';

import LocaleContext from 'components/context/Locale';
import Head from 'components/layout/Head';
import PageContainer from 'components/layout/PageContainer';
import LayoutDefault from 'components/modules/public/layouts/Default';

import SectionArticles from './sections/Articles';
import SectionHero from './sections/Hero';
import SectionJobLocations from './sections/JobLocations';
import SectionLeads from './sections/Leads';
import SectionNewsletter from './sections/Newsletter';
import SectionOffers from './sections/Offers';
import SectionRecommendedOffers from './sections/RecommendedOffers';
import SectionRegister from './sections/Register';
import StyledComponent from './styles';
import { Props } from './types';

const PublicPageHome: NextPage<Props> = ({ topJobs, jobs, jobTimes, articles, cities, state, cmsPage }) => {
    const localeContext = useContext(LocaleContext);
    const data = localeContext?.translations?.home;
    const sectionInfoVisible = state.profile ? false : true;
    return (
        <StyledComponent className="public-page-home">
            <Head
                {...data.meta}
                title={cmsPage?.metaTitle || data.meta.title}
                description={cmsPage?.metaDescription || data.meta.description}
                robots={cmsPage?.metaRobots}
                image={cmsPage?.image?.imageUrl || data?.meta?.image}
            />
            <LayoutDefault>
                <PageContainer>
                    <SectionHero
                        translations={{
                            ...data.content.hero,
                            ...selectCmsPageSection(cmsPage, 'hero'),
                        }}
                        form={{
                            ...data.content.hero.form,
                            mainBlockProps: {
                                ...data.content.hero.form.mainBlockProps,
                                jobTimes,
                            },
                        }}
                    />
                    <SectionRecommendedOffers
                        translations={{
                            ...data.content.recommendedOffers,
                            ...selectCmsPageSection(cmsPage, 'recommendedoffers'),
                        }}
                        jobs={topJobs}
                        iconsAlts={data.content.iconsAlts}
                    />
                    <SectionOffers
                        translations={{
                            ...data.content.offers,
                            ...selectCmsPageSection(cmsPage, 'offers'),
                        }}
                        jobs={jobs}
                        iconsAlts={data.content.iconsAlts}
                    />
                    {sectionInfoVisible && (
                        <SectionRegister
                            translations={{
                                ...data.content.register,
                                ...selectCmsPageSection(cmsPage, 'register'),
                            }}
                        />
                    )}
                    <SectionArticles
                        translations={{
                            ...data.content.articles,
                            ...selectCmsPageSection(cmsPage, 'articles'),
                        }}
                        articles={articles}
                    />
                    {/* <SectionWebinar {...data.content.webinar} /> */}
                    {/* <SectionTutorials
                        {...data.content.tutorials}
                        articles={articlesTutorials}
                    /> */}
                    <SectionNewsletter
                        translations={{
                            ...data.content.newsletter,
                            ...selectCmsPageSection(cmsPage, 'newsletter'),
                        }}
                    />
                    <SectionJobLocations
                        cities={cities}
                        translations={{
                            ...data.content.jobLocations,
                            ...selectCmsPageSection(cmsPage, 'joblocations'),
                        }}
                    />
                    <SectionLeads
                        translations={{
                            ...data.content.leads,
                            ...selectCmsPageSection(cmsPage, 'leads'),
                        }}
                    />
                </PageContainer>
            </LayoutDefault>
        </StyledComponent>
    );
};

export default PublicPageHome;
