import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: 5em 0;
    background-color: #10F5B0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .layout-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .public-layout-description {
        width: 50%;
    }

    .image {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 99;
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        object-position: center;
    }

    @media all and (max-width: ${variables.tabletS}) {}

    @media all and (max-width: ${variables.mobileM}) {
        flex-direction: column;
        padding-top: 0;

        .public-layout-description {
            width: 100%;
            padding-top: 3em;
        }

        .image {
            position: relative;
            width: 100%;
            min-height: 25em;
            background-size: cover;
        }
    }
`;