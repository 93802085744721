import { connect } from 'react-redux';
import { NextPageContext } from 'next';

import CmsPage from 'models/CmsPage';
import HomePage from 'models/HomePage';

import { single as singleCmsPage } from 'requests/cmsPages';
import { listHomePage } from 'requests/pages';
import { State as StoreState } from 'store/state';

import Component from './component';
import { Props } from './types';

const mapStateToProps = (state: StoreState, ownProps?: Props): object => {
    return {
        state: {
            profile: state.user.profile,
        },
    };
};

Component.getInitialProps = async (appContext: NextPageContext): Promise<Props> => {
    let cmsPage: CmsPage = null;
    let homePage: HomePage = null;

    try {
        await Promise.all([
            listHomePage({}),
            singleCmsPage({ slug: 'home' }),
        ]).then(responses => {
            homePage = responses[0];
            cmsPage = responses[1];
        });
    } catch (error) {
        console.error(error);
    }

    return {
        topJobs: homePage.jobs_top || null,
        jobs: homePage.jobs || null,
        jobTimes: homePage.jobTimes || null,
        articles: homePage.articles || null,
        cities: homePage.cities|| null,
        cmsPage: cmsPage || null,
    };
};

export default connect(mapStateToProps, null)(Component);
