import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: 5em 0;
    background-color: #F3F3F3;

    .layout-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-inputs {
            align-items: flex-start;

            .layout-forms-input {
                .layout-forms-input-wrapper {
                    .label-wrapper {
                        display: none;
                    }
                }

                .error-container {
                    padding-top: .6em;
                }
            }
        }
    }

    .public-layout-description {
        width: 43%;
    }

    @media all and (max-width: ${variables.tabletS}) {}

    @media all and (max-width: ${variables.mobileM}) {
        padding: 5em 1em;

        .public-layout-description {
            width: 100%;
        }

        .animation {
            display: none;
        }
    }
`;