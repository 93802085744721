import City from 'models/City';

import Component from './component';

export interface Props {
    city: City;
}

Component.defaultProps = {};

export default Component;