import { FunctionComponent } from 'react';

import LayoutContainer from 'components/layout/LayoutContainer';
import HeroSlider from 'components/modules/public/layout/sections/HeroSlider';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutSectionHero: FunctionComponent<Props> = ({ background, underline, layoutContainerProps, headline, subheadline, children }) => {
    return (
        <StyledComponent className='public-layout-section-hero'>
            <div>
                {background.desktop.map(element => (
                    <HeroSlider
                        key={element.id}
                        id={Number(element.id)}
                        background={element.href}
                        background1x={element.href1x}
                        background2x={element.href2x}
                        background3x={element.href3x}
                    />
                ))}
            </div>
            <LayoutContainer {...layoutContainerProps}>
                <div className="copy">
                    {headline && (
                        <div className="headline-wrapper">
                            <h1
                                className="headline"
                                dangerouslySetInnerHTML={{ __html: headline }}
                                style={{
                                    backgroundImage: `url(${underline})`,
                                }}
                            />
                        </div>
                    )}
                    {subheadline && (
                        <div className="headline-wrapper">
                            <div
                                className="subheadline"
                                dangerouslySetInnerHTML={{ __html: subheadline }}
                                style={{
                                    backgroundImage: `url(${underline})`,
                                }}
                            />
                        </div>
                    )}
                </div>
            </LayoutContainer>
            {children && children}
        </StyledComponent>
    );
};

export default PublicLayoutSectionHero;