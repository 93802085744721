import { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';

import LayoutContainer from 'components/layout/LayoutContainer';
import Spinner from 'components/layout/Spinner/component';
import Description from 'components/modules/public/layout/sections/Description';

import StyledComponent from './styles';
import { Props } from './types';

const DynamicRegisterAnimation = dynamic(
    () => import('components/modules/public/layout/animations/RegisterAnimation'),
    {
        loading: () => <Spinner />,
        ssr: false,
    }
);

const PublicPageHomeSectionRegister: FunctionComponent<Props> = ({ layoutContainerProps, translations: { descriptionProps } }) => {
    return (
        <StyledComponent
            className="public-page-home-section-register"
        >
            <LayoutContainer
                {...layoutContainerProps}
            >
                <Description {...descriptionProps} />
                <DynamicRegisterAnimation />
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicPageHomeSectionRegister;