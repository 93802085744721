import ApiCmsPageSection from 'types/api/CmsPageSection';

import Model from 'models/Model';

export default class CmsPage implements Model {
    id: string;
    name: string;
    slug: string;
    content: {[key:string]: any};

    constructor(data: ApiCmsPageSection) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.content = JSON.parse(data.content);
    }
}
