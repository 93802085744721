import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    transition: background-size .3s ease-in-out;
    cursor: pointer;
    .link {
        text-decoration: none;
        color: inherit;
    }
	.box-content {
        .name {
            font-size: 1.1em;
            font-weight: 700;
            text-transform: capitalize;
        }
        .jobs-count {
            font-size: 1.1em;
        }
        &.layoutLeft {
            text-align: center;
        }
        &.layoutCenter {
            text-align: center;
            color: #FFFFFF;
            padding: 3em 0;
        }
    }
    &:hover {
        background-size: 120%;

        .box-content {
            .name {
                text-decoration: underline;
            }
        }
    }
    @media all and (max-width: ${variables.mobileM}) {
        .box-content {
            font-size: 140%;
        }
    }
`;
