import Component from './component';

export interface Props {
    background?: string;
    background1x?: string;
    background2x?: string;
    background3x?: string;
    id?: number,
}

Component.defaultProps = {
    background: null,
};

export default Component;