import ApiCmsPage from 'types/api/CmsPage';

import CmsPageSection from 'models/CmsPageSection';
import FileUpload from 'models/FileUpload';
import Model from 'models/Model';

export default class CmsPage implements Model {
    id: string;
    name: string;
    slug: string;
    metaTitle: string;
    metaDescription: string;
    metaKeywords: string;
    metaRobots: string;
    image: FileUpload;
    sections: CmsPageSection[];

    constructor(data: ApiCmsPage) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.metaTitle = data.metaTitle;
        this.metaDescription = data.metaDescription;
        this.metaKeywords = data.metaKeywords;
        this.metaRobots = data.metaRobots;
        this.image = data.image && new FileUpload(data.image);
        this.sections = Array.isArray(data.sections)
            ? data.sections.map(element => new CmsPageSection(element))
            : [];
    }
}
